import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Collapse, Slide } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';

import { getEnvVars } from 'helpers/common';
import { useRouterHelper } from 'hooks/useRouterHelper';
import { CLICKS, LEADS, SELL, THEME_DEFAULT } from 'consts';
import { useBreakpoints } from 'helpers/styles';
import { useIsDev } from 'hooks/hooks';
import Link from 'components/Link';
import MarketTypeToggleGroup from 'containers/MarketTypeToggleGroup';
import BlIcon from 'components/icons/BlIcon';
import WrapIf from 'components/WrapIf';
import { logOut, selectAccount, selectMe } from 'store/slices/accountSlice';

import { useMarketTypeLS } from 'hooks/useMarketTypeLS';
import { footerLinks } from '../Footer/helpers';
import s from './styled';

const { REACT_APP_DISABLE_LEADS, REACT_APP_DISABLE_SELL } = getEnvVars();

const MainDrawer = memo(function MainDrawer(props) {
  const { open: openProp, onToggle: onToggleProp } = props;

  const {
    custom: { elements: { logoBig, logoSmall } = {} },
    ...theme
  } = useTheme();

  const { isDev } = useIsDev();

  const me = useSelector(selectMe, shallowEqual);

  const history = useHistory();
  const { isMdUp } = useBreakpoints();
  const dispatch = useDispatch();

  const { marketType } = useMarketTypeLS();
  const { isBuy, isSell } = useRouterHelper();
  const isNotBuySell = !isBuy && !isSell;
  const marketSell = marketType === SELL;

  const { buyer } = useSelector(selectAccount, shallowEqual);
  const { isLeadBuyer, isClickBuyer } = buyer || {};

  const signUpProductType = localStorage.getItem('signUpProductType');

  /**
   * Open state
   */
  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  const onToggle = useCallback(
    (event) => {
      setOpen(!open);
      onToggleProp(event, !open);
    },
    [onToggleProp, open],
  );

  const onNavClick = useCallback(
    (event) => {
      if (isMdUp) return;

      setOpen(false);
      onToggleProp(event, false);
    },
    [isMdUp, onToggleProp],
  );

  /**
   * Demo urls
   */

  const [demoUrlsOpened, setDemoUrlsOpened] = useState(false);

  const onDemoUrlsClick = useCallback(() => {
    setDemoUrlsOpened((o) => !o);
  }, []);

  /**
   * Specific callbacks
   */

  const onLogOut = useCallback(async () => {
    await dispatch(logOut());
    history.push('/auth/login');
  }, [dispatch, history]);

  return (
    <WrapIf
      Wrapper={s.Drawer}
      if={isMdUp}
      variant="permanent"
      anchor={isMdUp ? 'left' : 'right'}
      $open={open}
      $isNotBuySell={isNotBuySell}
      $marketSell={marketSell}
    >
      <WrapIf
        Wrapper={s.Dialog}
        if={!isMdUp}
        fullScreen
        open={open}
        TransitionComponent={Slide}
        TransitionProps={{
          direction: 'left',
        }}
      >
        {isMdUp && (
          <>
            <s.LogoBox $open={open} component={Link} to="/">
              {logoBig && open && <img src={logoBig} alt="Logo" />}
              {logoSmall && !open && <img src={logoSmall} alt="Logo" />}
              {theme.id === THEME_DEFAULT && <>Logo</>}
            </s.LogoBox>
            <s.Divider />
          </>
        )}
        <s.List
          component="div"
          subheader={
            <s.ListSubheader component="div">
              {open && <span>Your products </span>}
              {isMdUp && (
                <s.MenuToggleButton onClick={onToggle} $open={open}>
                  <BlIcon icon="nav-control" />
                </s.MenuToggleButton>
              )}
              {!REACT_APP_DISABLE_SELL && !isMdUp && <MarketTypeToggleGroup />}
            </s.ListSubheader>
          }
        >
          <s.ListTop>
            {(marketSell
              ? [
                  !REACT_APP_DISABLE_SELL && {
                    label: 'Leads',
                    icon: 'leads',
                    url: '/sell/leads/overview',
                    matchUrl: '/sell/leads',
                  },
                ]
              : [
                  !REACT_APP_DISABLE_LEADS &&
                    (isLeadBuyer || signUpProductType === LEADS) && {
                      label: 'Leads',
                      icon: 'leads',
                      url: '/buy/leads/overview',
                      matchUrl: '/buy/leads',
                    },
                  (isClickBuyer || signUpProductType === CLICKS) && {
                    label: 'Enhanced Clicks',
                    icon: 'union',
                    url: '/buy/clicks/overview',
                    matchUrl: '/buy/clicks',
                  },
                ].filter(Boolean)
            ).map((item) => {
              return (
                <Route path={item.matchUrl} key={item.label}>
                  {({ match }) => (
                    <s.ListTopItem
                      button
                      selected={!!match}
                      component={Link}
                      to={item.url}
                      $marketSell={marketSell}
                      onClick={onNavClick}
                    >
                      <s.ListItemIcon>
                        <BlIcon icon={item.icon} />
                      </s.ListItemIcon>

                      {open && <s.ListItemText primary={item.label} />}

                      {!isMdUp && (
                        <s.ListItemIcon>
                          <BlIcon
                            icon="arrow-right"
                            className="BlIcon-arrowIcon"
                          />
                        </s.ListItemIcon>
                      )}
                    </s.ListTopItem>
                  )}
                </Route>
              );
            })}
          </s.ListTop>

          <s.ListBottom className="ListBottom">
            {isMdUp ? <s.Divider /> : <Box height={80} />}

            <s.ListUserInfo component="div">
              <s.ListItemAvatar>
                <Avatar>{me?.firstName?.charAt(0)}</Avatar>
              </s.ListItemAvatar>
              {open && (
                <s.ListItemText
                  primary={
                    !me ? 'Anonymous' : `${me?.firstName} ${me?.lastName}`
                  }
                />
              )}
            </s.ListUserInfo>

            {isDev && (
              <>
                <s.ListBottomItem
                  button
                  selected={demoUrlsOpened}
                  onClick={onDemoUrlsClick}
                >
                  <s.ListItemIcon>
                    <BlIcon icon="hide" />
                  </s.ListItemIcon>
                  {open && <s.ListItemText primary="Demo urls" />}

                  {demoUrlsOpened && open && <BlIcon icon="arrow-up" />}

                  {!demoUrlsOpened && open && <BlIcon icon="arrow-down" />}
                </s.ListBottomItem>

                <Collapse in={demoUrlsOpened} timeout="auto" unmountOnExit>
                  <s.List component="div" disablePadding>
                    {[
                      {
                        label: 'Style system',
                        url: '/stylesystem',
                      },
                      {
                        label: 'Not found',
                        url: '/not-found',
                      },
                      {
                        label: 'Login',
                        url: '/auth/login',
                      },
                      {
                        label: 'Reset password',
                        url: '/auth/reset-password',
                      },
                      {
                        label: 'Create password',
                        url: '/auth/reset-password',
                      },
                      {
                        label: 'Create leads campaign',
                        url: '/buy/leads/create-campaign',
                      },
                      {
                        label: 'Create clicks campaign',
                        url: '/buy/clicks/create-campaign',
                      },
                      {
                        label: 'Set up billing',
                        url: '/setup-billing',
                      },
                      {
                        label: 'Leads Overview',
                        url: '/buy/leads/overview',
                      },
                      {
                        label: 'Leads Campaigns',
                        url: '/buy/leads/campaigns',
                      },
                      {
                        label: 'Leads Duplicate Campaign',
                        url: '/buy/leads/duplicate-campaign',
                      },
                      {
                        label: 'Leads Campaign Detail',
                        url: '/buy/leads/campaigns/1',
                      },
                      {
                        label: 'Leads Campaign Details: all locations page',
                        url: '/buy/leads/campaigns/1/geo',
                      },
                      {
                        label: 'Lead Detail',
                        url: '/buy/leads/leads/1',
                      },
                      {
                        label: 'Buy Leads Welcome',
                        url: '/buy/leads/welcome',
                      },
                      {
                        label: 'Buy Clicks Overview',
                        url: '/buy/clicks/overview',
                      },
                      {
                        label: 'Buy Clicks Categories',
                        url: '/buy/clicks/campaigns',
                      },
                      {
                        label: 'Buy Clicks Campaigns',
                        url: '/buy/clicks/campaigns/1',
                      },
                      {
                        label: 'Buy Clicks Campaigns Locations',
                        url: '/buy/clicks/campaigns/1/2/locations',
                      },
                      {
                        label: 'Buy Clicks Welcome',
                        url: '/buy/clicks/welcome',
                      },
                      {
                        label: 'Finances',
                        icon: 'wallet',
                        url: '/finances/overview',
                        matchUrl: '/finances',
                      },
                    ].map(({ label, url }) => (
                      <s.ListBottomItem
                        key={label + url}
                        button
                        $nested
                        component={Link}
                        to={url}
                        onClick={onNavClick}
                      >
                        <s.ListItemText primary={label} />
                      </s.ListBottomItem>
                    ))}
                  </s.List>
                </Collapse>
              </>
            )}

            {[
              {
                label: 'Analytics',
                icon: 'graph',
                url: '/analytics',
              },
              {
                label: 'My Account',
                icon: 'account',
                url: '/account/overview',
                matchUrl: '/account',
              },
              {
                label: 'Get Help',
                icon: 'info',
                url: '/info',
              },
              !!me && {
                label: 'Log Out',
                icon: 'log-out',
                onClick: onLogOut,
              },
              !me && {
                label: 'Log In',
                icon: 'log-out',
                url: '/auth/login',
              },
            ]
              .filter(Boolean)
              .map(({ icon, label, url, matchUrl, ...other }, index) => {
                const listItem = (
                  <Route path={matchUrl || url} key={label + icon + url}>
                    {({ match }) => (
                      <s.ListBottomItem
                        key={label + icon + url}
                        button
                        selected={!!(match && (matchUrl || url))}
                        component={!!url ? Link : undefined}
                        to={url || undefined}
                        onClick={onNavClick}
                        {...other}
                      >
                        <s.ListItemIcon>
                          <BlIcon icon={icon} />
                        </s.ListItemIcon>
                        {open && <s.ListItemText primary={label} />}
                      </s.ListBottomItem>
                    )}
                  </Route>
                );

                return index === 2 ? (
                  <React.Fragment key={label + icon + url}>
                    {listItem}
                    <s.Divider />
                  </React.Fragment>
                ) : (
                  listItem
                );
              })}

            {!isMdUp && (
              <>
                <s.Divider />
                <div>
                  {footerLinks.map(
                    ({ label, url, isExternalLink, ...other }) => {
                      return (
                        <s.FooterListItem
                          component="span"
                          key={label}
                          {...other}
                        >
                          {isExternalLink && (
                            <s.ExternalLink href={url} target="_blank">
                              {label}
                            </s.ExternalLink>
                          )}
                          {!isExternalLink && <s.Link to={url}>{label}</s.Link>}
                        </s.FooterListItem>
                      );
                    },
                  )}
                </div>
              </>
            )}
          </s.ListBottom>
        </s.List>
      </WrapIf>
    </WrapIf>
  );
});

MainDrawer.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default MainDrawer;
